
import { mapActions, mapState } from "pinia";
import { usePopMetricsStore } from "~/store/pops/metrics";
import { useIntegrationsStore } from "~/store/integrations";
import keys from "lodash/keys";

export default {
  props: {
    period: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      chartOptions: {
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                display: false,
              }
            }
          ]
        }
      }
    }
  },
  watch: {
    period: {
      deep: true,
      immediate: true,
      handler(value) {
        if(!value) return

        this.omittedTemplateHandles = []
        this.actionDataMap[this.action].loadData({ startDate: value.from, endDate: value.to })
      }
    }
  },
  methods: {
    ...mapActions(usePopMetricsStore, [
      'colorFromMD5',
      'loadTemplatesViewsAggregations',
      'loadTemplatesClicksAggregations',
    ]),
    templateHandleTitle(templateHandle) {
      return this.integrationHandleMap[templateHandle]?.title
    },
  },
  computed: {
    ...mapState(useIntegrationsStore, ["integrationHandleMap"]),
    ...mapState(usePopMetricsStore, [
      'templatesViewsAggregations',
      'templatesClicksAggregations',
      'granularityData',
    ]),
    chartData() {
      const labels = this.templateHandles.map(templateHandle => this.templateHandleTitle(templateHandle))
      const backgroundColors = this.templateHandles.map(templateHandle => this.colorFromMD5(templateHandle))

      return {
        labels,
        datasets: [{
          backgroundColor: backgroundColors,
          maxBarThickness: 30,
          data: Object.values(this.aggregationsData.total_count),
        }]
      }
    },
    templateHandles() {
      return keys(this.aggregationsData.total_count)
    },
    loading() {
      return this.actionDataMap[this.action].loading
    },
    aggregationsData() {
      return this.actionDataMap[this.action].aggregationsData || { template_handles: [] }
    },
    actionDataMap() {
      return {
        popViews: {
          headerText: 'Total Pop Views',
          loadData: this.loadTemplatesViewsAggregations,
          loading: this.loadingTemplatesViewsAggregations,
          aggregationsData: this.templatesViewsAggregations
        },
        popClicks: {
          headerText: 'Total Pop Clicks',
          loadData: this.loadTemplatesClicksAggregations,
          loading: this.loadingTemplatesClicksAggregations,
          aggregationsData: this.templatesClicksAggregations
        }
      }
    }
  },
}
